import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => {

  return (
    <Layout >
      <SEO title="About" />
      <h1>About</h1>
      <p><h5>Hello! My name is Angie. I have been a Registered Nurse for 6 years. I have learned so much throughout my career and wanted to start a blog to share my experiences and what I know.</h5></p>
    </Layout>
  )
}

export default AboutPage